import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import Postmate from 'postmate';
import { TextField, Button, SVG_HOST_URL, Pill, ProgressCircle } from 'factor';
import {
  AuthService,
  validationUtils,
  TwoPartScreen,
  TwoPartScreenStyles,
  useAdblockCheck,
  ADBLOCK_MESSAGE,
  useCapslockCheck,
  CAPSLOCK_MESSAGE,
  ORIGIN_URL,
} from 'iqm-framework';

import { PasswordField } from 'components/PasswordField';
import { API } from 'api';
import { useInitData } from 'utils/useInitData';
import { localStorageService } from 'services/localStorage';
import { User } from 'models/User';

import styles from './index.module.scss';

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/brand/intelligence2Asset2.svg`;
const EMAIL_INVALID_MSG = "Sorry, we couldn't recognize this email address";

interface Props {
  onSuccess: (userData: unknown) => void;
  onError: (error: unknown) => void;
}

export const LoginForm = (props: Props) => {
  const { onSuccess, onError } = props;
  const [visibleCard, setVisibleCard] = useState<1 | 2>(1);
  const [passwd, setPasswd] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailValidity, setEmailValidity] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isAutoLogin, setAutoLogin] = useState<boolean>(false);
  const [submitData, setSubmitData] = useState({ apiError: '', isSubmitting: false });
  const [adblockEnabled, setAdblockEnabled] = useAdblockCheck();
  const [isCapsLock] = useCapslockCheck();
  const history = useHistory();
  const pwdRef = useRef<HTMLInputElement | null>(null);
  const mounted = useRef<boolean>(false);

  const { data: initData, sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  const getOrgName = useCallback(() => {
    return initData?.organizationName || 'IHP';
  }, [initData?.organizationName]);

  const handleEmailVerification = useCallback(
    async (emailParam = '') => {
      if (emailValidity) {
        const workspaceDomain = ORIGIN_URL.substring(ORIGIN_URL.indexOf('//') + 2);

        const res = await API.Customer.verifyEmail({
          workspaceDomain,
          email: emailParam || emailAddress,
        });

        if (res.success && res.data?.isWorkspaceAllowed) {
          setVisibleCard(2);
          setTimeout(() => pwdRef.current?.focus(), 0);
        } else if (res.data?.isValid && initData?.ihpOwner) {
          history.push({
            pathname: '/enter-workspace',
            state: {
              userEmail: emailParam || emailAddress,
            },
          });
        } else if (!res.success && res.errorObjects?.length) {
          setErrorMsg(res.errorObjects[0].error ?? EMAIL_INVALID_MSG);
        } else {
          setErrorMsg(EMAIL_INVALID_MSG);
        }
      }
    },
    [emailAddress, emailValidity, history, initData?.ihpOwner],
  );

  useEffect(() => {
    if (history.location.search && !mounted.current) {
      mounted.current = true;

      const owId = Number(new URLSearchParams(history.location.search).get('ow'));
      // const ihpParam = new URLSearchParams(history.location.search).get('owner');
      const emailParam = new URLSearchParams(history.location.search).get('email');
      let apiToken = '';
      try {
        apiToken = atob(new URLSearchParams(history.location.search).get('apiToken') ?? '');
      } catch (e) {
        apiToken = '';
      }
      if (apiToken && !Number.isNaN(owId)) {
        let currentIndex = 0;
        const savedOwIds = JSON.parse(localStorage.getItem('owIds') || '[]') || [];
        const index = savedOwIds.indexOf(owId);
        if (index !== -1) {
          currentIndex = index;
        } else {
          const newList = [...savedOwIds, owId];
          localStorage.setItem('owIds', JSON.stringify(newList));
          currentIndex = newList.length - 1;
        }
        localStorage.setItem('transitionOWIdIndex', String(currentIndex));

        setAutoLogin(true);
        AuthService.singleSignon(apiToken, [owId])
          .then((userData: unknown) => {
            onSuccess(userData);
          })
          .catch((e: unknown) => {
            if (onError) {
              onError(e);
              localStorage.removeItem('transitionOWIdIndex');
            }
          });
      } else if (emailParam) {
        const emailToSave = atob(emailParam);
        setEmailAddress(emailToSave);
        setVisibleCard(2);
        setTimeout(() => pwdRef.current?.focus(), 0);
      }
    } else if (localStorageService.getBaseInfo<User>()?.apiToken) {
      setTimeout(() => {
        window.location.href = '/dashboard/u/0/';
      }, 0);
    }
  }, [history.location.search, onError, onSuccess]);

  // clear the error if user modifies the email
  useEffect(() => {
    setErrorMsg('');
  }, [emailAddress]);

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitData({ apiError: '', isSubmitting: true });

    try {
      const userData = await AuthService.authorize({ email: emailAddress, password: passwd }, true);
      if (onSuccess) {
        onSuccess(userData);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      setSubmitData({
        apiError: error.errorMessage,
        isSubmitting: false,
      });
      if (!error.errorMessage) {
        setAdblockEnabled(error.message === 'Endpoint is not available');
      }
    }
  };

  const renderSignupLink = () => (
    <div className={styles.text}>
      Not registered yet?
      <Link
        className={`${initData?.ihpOwner ? styles.ihpLink : styles.nonIhpLink}`}
        to={{ pathname: '/signup' }}
      >
        Signup now
      </Link>
    </div>
  );

  return (
    <>
      <div id="iframe-sso-container" style={{ display: 'none' }} />
      <TwoPartScreen
        rightPartImage={sideURL}
        docTitle="Login"
        leftChildren={
          <>
            <div
              className={`${TwoPartScreenStyles.card} ${
                visibleCard === 1 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
              }`}
            >
              {isAutoLogin ? (
                <>
                  <div className={TwoPartScreenStyles.title}>Logging you in...</div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '18rem' }}
                  >
                    <ProgressCircle size={80} />
                  </div>
                </>
              ) : (
                <>
                  <div className={TwoPartScreenStyles.title}>Let's get you started!</div>
                  <div className={TwoPartScreenStyles.subtitle}>Enter your email to sign in</div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleEmailVerification();
                    }}
                  >
                    <TextField
                      variant="withoutTickbox"
                      name="email"
                      value={emailAddress}
                      onChange={setEmailAddress}
                      label="Email ID"
                      placeholder="Enter your email ID"
                      className="mt-5"
                      onValidate={setEmailValidity}
                      inputAttributes={{
                        autoComplete: 'on',
                        autoFocus: true,
                      }}
                      validationRules={[
                        {
                          func: validationUtils.validateEmail,
                          error: () => {
                            return 'Please enter a valid email address';
                          },
                        },
                      ]}
                    />
                    {errorMsg ? (
                      <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>
                    ) : null}
                    <Button
                      variant="primary"
                      iconName="Right"
                      className={TwoPartScreenStyles.button}
                      iconPosition="right"
                      disabled={!emailValidity}
                      type="submit"
                    >
                      Next
                    </Button>
                  </form>
                  {renderSignupLink()}
                </>
              )}
            </div>
            <div
              className={`${TwoPartScreenStyles.card} ${
                visibleCard === 2 ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
              }`}
            >
              <div className={TwoPartScreenStyles.title}>Login to {getOrgName()}</div>
              <div className={TwoPartScreenStyles.subtitle}>Enter your login password</div>
              <div
                className={TwoPartScreenStyles.readonlyEmail}
                onClick={() => {
                  setVisibleCard(1);
                  setSubmitData({
                    apiError: '',
                    isSubmitting: false,
                  });
                }}
              >
                <Pill label={emailAddress} iconName="EditAlt" iconPosition="right" />
              </div>
              <form onSubmit={handleSubmitForm}>
                <input type="text" name="email" style={{ display: 'none' }} value={emailAddress} />
                <PasswordField
                  className={TwoPartScreenStyles.passwordField}
                  name="password"
                  placeholder="Password"
                  value={passwd}
                  onChange={setPasswd}
                  inputAttributes={{
                    autoComplete: 'on',
                    id: 'passwordField',
                  }}
                  inputRef={(ref: HTMLInputElement) => {
                    pwdRef.current = ref;
                  }}
                />
                <div className={styles.passwordNote}>
                  {initData?.passwordNote ? `Note: ${initData.passwordNote}` : ''}
                </div>
                {isCapsLock ? (
                  <div className={TwoPartScreenStyles.warningMessage}>{CAPSLOCK_MESSAGE}</div>
                ) : null}
                {adblockEnabled ? (
                  <div className={TwoPartScreenStyles.errorMessage}>{ADBLOCK_MESSAGE}</div>
                ) : null}
                {submitData.apiError && !submitData.isSubmitting ? (
                  <div className={TwoPartScreenStyles.errorMessage}>{submitData.apiError}</div>
                ) : null}

                <Button
                  variant="primary"
                  iconName="Right"
                  className={isCapsLock ? 'mt-3' : TwoPartScreenStyles.button}
                  iconPosition="right"
                  disabled={passwd.length === 0 || adblockEnabled || submitData.isSubmitting}
                  type="submit"
                >
                  Login
                </Button>
              </form>
              <Link
                className={TwoPartScreenStyles.link}
                to={{ pathname: '/reset-password-email', state: { emailAddress } }}
              >
                Forgot Password?
              </Link>
            </div>
          </>
        }
      />
    </>
  );
};
