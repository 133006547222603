import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TextField, Button, SVG_HOST_URL, Pill, Select, Icon } from 'factor';
import { validationUtils, TwoPartScreen, TwoPartScreenStyles } from 'iqm-framework';

import { API } from 'api';
import { useInitData } from 'utils/useInitData';

import styles from './index.module.scss';

interface option {
  label: string;
  value: number;
}

interface FormValues {
  [key: string]: string | number | option;
}

interface IAPIFields {
  [key: string]: string;
}

interface CustomerSignupRequest {
  [key: string]: string | number;
  email: string;
  userName: string;
  organizationName: string;
}

interface DropDownOptions {
  [key: string]: option[];
}

interface FormField {
  field: string;
  id: number;
  label: string;
  isUserRequired: boolean;
  type: string;
  inputType: string;
  placeholder: string;
}

interface FieldValidity {
  [key: string]: boolean;
}

const IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/office-people.svg`;
const NON_IHB_IMAGE_URL = `${SVG_HOST_URL}/misc/fingerprint.svg`;
const IHB_INVITE_CARD_URL = `${SVG_HOST_URL}/misc/email-invite-wait-primary.svg`;
const NON_IHB_INVITE_CARD_URL = `${SVG_HOST_URL}/misc/email-invite-wait.svg`;
const EMAIL_INVALID_MSG =
  'This email address owns an organization account on the platform. Please use a different email address.';

const EMAIL_CARD = 'enterEmail';
const DETAILS_CARD = 'enterDetails';
const INVITE_CARD = 'emailInvite';

export type CardState = 'enterEmail' | 'enterDetails' | 'emailInvite';

export const SignupPage = () => {
  const [visibleCard, setVisibleCard] = useState<CardState>(EMAIL_CARD);
  const [isCustomerVerificationEnabled, setCustomerVerificationStatus] = useState<boolean>();
  const [emailValidity, setEmailValidity] = useState<boolean>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [signUpErrorMsg, setSignUpErrorMsg] = useState<string>('');
  const [dropDownOptions, setDropDownOptions] = useState<DropDownOptions>({});
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formFields, setFormFields] = useState<FormField[]>([]);
  const [fieldValidity, setFieldValidity] = useState<FieldValidity>({});
  const [intercomId, setIntercomId] = useState<string | undefined>('');

  const setIntercom = async () => {
    try {
      const data = await API.Customer.getIntercomId();
      setIntercomId(data.data?.chatbotAppId);
    } catch (e) {
      // eslint-disable-next-line
      console.log('intercom:', e);
    }
  };

  useEffect(() => {
    setIntercom();
  }, []);

  const { data: initData, sideURL } = useInitData({
    ihb: IHB_IMAGE_URL,
    nonIHB: NON_IHB_IMAGE_URL,
  });

  const ihpOwner = initData?.ihpOwner;

  const getInviteSentImage = () => (ihpOwner ? IHB_INVITE_CARD_URL : NON_IHB_INVITE_CARD_URL);

  const getDropDownOptions = async (fields: { type: string; field: string }[]) => {
    const API_KEYS: IAPIFields = {
      mediaBudget: 'media-budget',
      industry: 'industries',
      companySize: 'company-size',
    };
    const selectFields: string[] = [];
    fields.forEach((element) => {
      if (element.type === 'select') {
        selectFields.push(API_KEYS[element.field]);
      }
    });
    if (selectFields.length) {
      const responses = await Promise.all(
        selectFields.map((field) => API.Customer.getDropDownOptions(field)),
      );
      const options: DropDownOptions = {};
      responses.forEach((res, idx) => {
        if (res.success && res.data) {
          const dropDown: keyof DropDownOptions | undefined = Object.keys(API_KEYS).find(
            (key) => API_KEYS[key] === selectFields[idx],
          );
          if (dropDown) {
            options[dropDown] = res.data.map((optionValue: { label: string; id: number }) => {
              return { label: optionValue.label, value: optionValue.id };
            });
          }
        }
      });
      setDropDownOptions(options);
    }
  };

  const handleUserEmailVerification = useCallback(
    async (emailParam = '') => {
      const res = await API.Customer.verifyUserEmail(formValues?.email || emailParam);
      if (res.success && res.data) {
        if (res.data.isOrganizationOwnerUser) {
          setErrorMsg(EMAIL_INVALID_MSG);
        } else {
          if (res.data?.userName) {
            setValue('userName', res.data.userName);
          }
          const signUpFormRes = await API.Customer.getCustomerSignupForm();
          if (signUpFormRes.success && signUpFormRes?.data) {
            const numberInputs = ['budgetSpent'];
            const selectFormFields = ['industry', 'companySize', 'mediaBudget'];
            const placeholderMessages: IAPIFields = {
              organizationName: 'Enter your organization name',
              userName: 'Enter your full name',
              mediaBudget: 'Select budget',
              budgetSpent: 'Enter budget',
              industry: 'Select industry',
              companySize: 'Select company size',
            };
            setCustomerVerificationStatus(signUpFormRes.data.isCustomerVerificationEnable);
            const fields = signUpFormRes.data.customerFormDetails.map((value) => {
              return {
                ...value,
                type: selectFormFields.includes(value.field) ? 'select' : 'text',
                inputType: numberInputs.includes(value.field) ? 'number' : 'string',
                placeholder: placeholderMessages[value.field],
              };
            });
            setFormFields(fields);
            if (fields.some((field) => field.type === 'select')) {
              await getDropDownOptions(fields);
              setVisibleCard(DETAILS_CARD);
            } else {
              setVisibleCard(DETAILS_CARD);
            }
          }
        }
      }
    },
    [formValues?.email],
  );

  const handleSignup = useCallback(async () => {
    const requestObj: CustomerSignupRequest = {
      email: '',
      userName: '',
      organizationName: '',
    };
    if (formValues) {
      const selectFormFields = ['industry', 'companySize', 'mediaBudget'];
      Object.keys(formValues).forEach((key: string) => {
        if (selectFormFields.includes(key)) {
          const selectedValue = formValues[key] as option;
          requestObj[key] = selectedValue.value;
        } else {
          const selectedValue = formValues[key] as string | number;
          requestObj[key] = selectedValue;
        }
      });
      const res = await API.Customer.signupCustomer(requestObj);
      if (res.success) {
        setVisibleCard(INVITE_CARD);
      } else if (res?.errorObjects) {
        setSignUpErrorMsg(res.errorObjects[0].error);
      }
    }
  }, [formValues]);

  const setValue = (key: string, value: string | option) => {
    if (key === 'budgetSpent') {
      if ((value.toString().length <= 10 && /^\d+$/.test(value as string)) || !value) {
        return setFormValues((prevState) => ({
          ...prevState,
          [key]: Number(value),
        }));
      }
      return null;
    }
    return setFormValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getValue = (field: string) => {
    if (formValues) {
      return formValues[field] ? formValues[field].toString() : '';
    }
    return '';
  };

  const isFormDataValid = () => {
    if (formValues && !Object.values(fieldValidity).includes(true)) {
      return formFields.some((element: { isUserRequired: boolean; field: string | number }) => {
        return element.isUserRequired && !formValues[element.field];
      });
    }
    return true;
  };

  const setValidity = (field: string, value: boolean) =>
    setFieldValidity((prevState) => {
      return {
        ...prevState,
        [field]: value,
      };
    });

  const checkLengthValidity = (value: string, element: { name: string }) => {
    const maxCharLength: { [key: string]: number } = {
      userName: 150,
      organizationName: 100,
    };
    if (value.trim().length <= maxCharLength[element.name]) {
      setValidity(element.name, false);
      return true;
    }
    setValidity(element.name, true);
    return false;
  };

  const changeEmail = (value: string) => {
    setErrorMsg('');
    setSignUpErrorMsg('');
    setValue('email', value);
  };

  const renderLoginLink = () => (
    <div className={styles.text}>
      Already registered?
      <Link
        className={` ${ihpOwner ? styles.ihpLink : styles.nonIhpLink}`}
        to={{ pathname: '/login' }}
      >
        Login here
      </Link>
    </div>
  );

  const renderEmailField = () => (
    <div
      className={`${TwoPartScreenStyles.card} ${
        visibleCard === EMAIL_CARD ? TwoPartScreenStyles.show : TwoPartScreenStyles.hide
      }`}
    >
      <div className={TwoPartScreenStyles.title}>Let's get you started!</div>
      <div className={TwoPartScreenStyles.subtitle}>Enter your email and press 'Enter'</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleUserEmailVerification();
          if (formValues.email && !!intercomId) {
            try {
              // eslint-disable-next-line
              window?.Intercom?.('update', {
                app_id: intercomId,
                email: formValues?.email,
              });
            } catch (error) {
              // eslint-disable-next-line
              console.log('error initalizing intercom', error);
            }
          }
        }}
      >
        <TextField
          variant="withoutTickbox"
          name="email"
          value={getValue('email')}
          onChange={(value: string) => changeEmail(value)}
          label="Email ID"
          placeholder="Enter your email ID"
          className="mt-5"
          onValidate={setEmailValidity}
          inputAttributes={{
            autoComplete: 'on',
            autoFocus: true,
          }}
          validationRules={[
            {
              func: validationUtils.validateEmail,
              error: () => {
                return 'Please enter a valid email address';
              },
            },
          ]}
        />
        {errorMsg && <div className={TwoPartScreenStyles.errorMessage}>{errorMsg}</div>}
        <Button
          variant="primary"
          iconName="Right"
          className={`${TwoPartScreenStyles.button} ${
            ihpOwner ? styles.ihpButton : styles.nonIhpButton
          }`}
          iconPosition="right"
          disabled={!emailValidity}
          type="submit"
        >
          Next
        </Button>
      </form>
      {renderLoginLink()}
    </div>
  );

  const renderSignupForm = () => (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formValues && !!intercomId) {
            try {
              // eslint-disable-next-line
              window?.Intercom?.('update', {
                app_id: intercomId,
                email: formValues?.email,
                name: formValues?.userName,
              });
            } catch (error) {
              // eslint-disable-next-line
              console.log('error initalizing intercom', error);
            }
          }
          handleSignup();
        }}
      >
        {visibleCard === DETAILS_CARD && (
          <div
            className={`${styles.signupCard} ${
              visibleCard === DETAILS_CARD ? styles.show : TwoPartScreenStyles.hide
            }`}
          >
            <div className={TwoPartScreenStyles.title}>Help us know you better</div>
            <div className={styles.subtitle}>Enter the below details and press 'Enter'</div>
            <div
              className={styles.readonlyEmail}
              onClick={() => {
                setVisibleCard(EMAIL_CARD);
              }}
            >
              <Pill
                label={
                  <>
                    <Icon className={styles.mailIcon} name="Mail" />
                    {formValues?.email}
                    <Icon className={styles.editIcon} name="EditAlt" />
                  </>
                }
              />
            </div>
            {formFields.map(
              (
                element: {
                  type: string;
                  field: string;
                  label: string;
                  inputType: string;
                  isUserRequired: boolean;
                  placeholder: string;
                },
                idx: number,
              ) =>
                element.type === 'text' ? (
                  <TextField
                    key={element.field}
                    variant="withoutTickbox"
                    name={element.field}
                    value={getValue(element.field)}
                    onChange={(value: string) => setValue(element.field, value)}
                    label={`${element.label}${element.isUserRequired ? ' *' : ''}`}
                    placeholder={element.placeholder}
                    className={`mt-5 ${element.field === 'budgetSpent' && styles.textField} ${
                      element.field === 'budgetSpent' && styles.Dollar
                    }`}
                    inputAttributes={{
                      autoFocus: idx === 0,
                    }}
                    validationRules={
                      element.inputType === 'string'
                        ? [
                            {
                              func: checkLengthValidity,
                              error: () => 'Input value exceeds the maximum allowed length',
                            },
                          ]
                        : []
                    }
                  />
                ) : (
                  <Select
                    key={element.field}
                    label={`${element.label}${element.isUserRequired ? ' *' : ''}`}
                    options={dropDownOptions[element.field]}
                    showLabelAlways
                    value={formValues ? formValues[element.field] : {}}
                    className="mt-5"
                    placeholder={element.placeholder}
                    onChange={(e: { value: number; label: string }) => setValue(element.field, e)}
                  />
                ),
            )}
            <Button
              variant="primary"
              iconName="Right"
              className={`${TwoPartScreenStyles.button} ${
                ihpOwner ? styles.ihpButton : styles.nonIhpButton
              }`}
              iconPosition="right"
              disabled={isFormDataValid()}
              type="submit"
            >
              Next
            </Button>
            {signUpErrorMsg && <div className={styles.errorMessage}>{signUpErrorMsg}</div>}
            {visibleCard === DETAILS_CARD && renderLoginLink()}
          </div>
        )}
      </form>
    </>
  );

  const renderInviteMsg = () => {
    return (
      <div
        className={`${styles.signupCard} ${
          visibleCard === INVITE_CARD ? styles.show : TwoPartScreenStyles.hide
        }`}
      >
        <Icon className={`mt-5 ${styles.successIcon}`} name="CheckCircle" />
        <div className={`${TwoPartScreenStyles.title} ${styles.successTitle}`}>
          {isCustomerVerificationEnabled ? 'Invite requested Successfully!' : 'Check your email'}
        </div>
        <div className={styles.subtitle}>
          {isCustomerVerificationEnabled
            ? 'Your request is under review, once your request is processed, the result will be sent to you by email.'
            : 'We have sent you a Sign Up link on the associated email.'}
        </div>
        <div className={styles.subtitle}>
          {isCustomerVerificationEnabled
            ? 'Please wait for email for further process.'
            : 'Check your email for continuing to Sign Up to your workspaces.'}
        </div>
      </div>
    );
  };

  return (
    <TwoPartScreen
      rightPartImage={visibleCard === INVITE_CARD ? getInviteSentImage() : sideURL}
      docTitle="Sign up"
      leftChildren={
        <>
          {renderEmailField()}
          {renderSignupForm()}
          {renderInviteMsg()}
        </>
      }
    />
  );
};
