import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'factor';
import { SimpleHeader } from 'iqm-framework';

import { useInitData } from 'utils/useInitData';

import styles from './index.module.scss';

export const AccessPage = () => {
  const { data: initData } = useInitData({});
  const ihpOwner = initData?.ihpOwner;
  return (
    <>
      <SimpleHeader docTitle="Access Page" />
      {initData && (
        <div className={styles.buttonWrapper}>
          <Link to={{ pathname: '/signup' }}>
            <Button
              variant="primary"
              className={`${ihpOwner ? styles.ihpSignUpBtn : styles.nonIhpSignUpBtn}`}
            >
              Signup
            </Button>
          </Link>
          <Link to={{ pathname: '/login' }}>
            <Button
              variant="primary"
              className={`${ihpOwner ? styles.ihpLoginBtn : styles.nonIhpLoginBtn}`}
            >
              Login
            </Button>
          </Link>
        </div>
      )}
    </>
  );
};
